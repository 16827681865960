<template>
  <v-app class="login" >
    <v-row class="">

      <Overlay :overlay="overlay"/>
      <v-col
          cols="12"
          class="d-flex justify-center align-center login-color"
      >
        <form
            @submit.prevent="login"
            style="width: 50%"
        >
          <v-row>
            <v-col cols="12" class="d-flex justify-center">
              <img src="../assets/images/logo.png" width="250px"/>
              <!--              <v-icon-->
              <!--                  class="primary&#45;&#45;text mr-2"-->
              <!--                  style="font-size: 30px"-->
              <!--              >fas fa-file-invoice-dollar-->
              <!--              </v-icon>-->

              <!--              <h1 style="font-size: 30px">Declara Fácil</h1>-->
            </v-col>

            <v-col
                v-if="!registerSucss && !loader2"
                cols="12"
                class="d-flex justify-center"
            >
              <p style="font-size: 24px">
                {{ panelSA ? "Crea tu cuenta" : "Inicia sesión en tu cuenta" }}
              </p>
            </v-col>
          </v-row>

          <v-row>
            <v-alert
                v-for="(error, idx) in getErrors"
                :key="idx"
                dense
                text
                type="error"
            >
              {{ error }}
            </v-alert>
          </v-row>

          <v-row v-if="!panelSA && !registerSucss">
            <v-col class="d-flex flex-column pa-0" cols="12" sm="12">
              <p class="ml-2">Email</p>
              <v-text-field
                  solo
                  v-model="user.email"
                  prepend-inner-icon="fas fa-at"
              ></v-text-field>
            </v-col>

            <v-col class="d-flex flex-column pa-0" cols="12" sm="12">
              <p class="ml-2">Password</p>
              <v-text-field
                  solo
                  v-model="user.password"
                  prepend-inner-icon="fas fa-lock"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
              ></v-text-field>

            </v-col>

            <v-btn
                class="pa-6"
                block
                style="color: white"
                color="primary"
                type="submit"
            >
              Iniciar sesión
            </v-btn>
            <!--            <p @click="panelSA = true" style="cursor: pointer;" class="ml-2 mt-5">¿No tienes una cuenta? Regístrate</p>-->
          </v-row>

          <v-row v-if="loader2">
            <v-col
                class="d-flex flex-column pa-0 align-center mt-5"
                cols="12"
                sm="12"
            >
              <v-progress-circular
                  class="mt-5"
                  :size="50"
                  color="primary"
                  indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
        </form>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Overlay from "@/components/Overlay.vue";

export default {
  name: "Login",
  components: {Overlay},
  computed: {
    ...mapGetters("auth", ["getErrors"]),
  },

  data() {
    return {
      user:{
        email:'admin@admin.mx',
        password:'temporal1021',
      },
      show1: false,
      overlay: false,
      dialog: false,
      messageConfirm: false,
      loader: false,
      panelSA: false,
      registerSucss: false,
      loader2: false,
    };
  },
  created() {
    this.setErrors("clear");

  },
  methods: {
    ...mapActions("auth", ["signin", "setErrors", "sendEmailRecoveryPass"]),
    ...mapActions("general", ['setThemeColor']),
    async login(){
      this.overlay = true;
      await this.signin(this.user)
      this.overlay = false;
    }
  },

};
</script>

<style >

</style>
